<form [formGroup]="printingFilterForm" novalidate>
  <mat-form-field>
    <input matInput [max]="maxFirstDate" formControlName="startDate" [matDatepicker]="pickerStart" [placeholder]="'start-date' | translate" />
    <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
  </mat-form-field>
  <mat-datepicker #pickerStart></mat-datepicker>

  <mat-form-field>
    <input matInput [min]="minLastDate" [max]="maxLastDate" formControlName="endDate" [matDatepicker]="pickerEnd" [placeholder]="'end-date' | translate" />
    <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
  </mat-form-field>
  <mat-datepicker #pickerEnd></mat-datepicker>

  <mat-form-field class="model-form-field">
    <mat-chip-grid class="chips-list" formControlName="models" #modelList aria-label="Model selection">
      @for (model of printingFilterForm.get('models').value; track model) {
        <mat-chip-row class="chip" [value]="model" (removed)="remove(model)">
          {{ model.name }}
          <button matChipRemove>
            <mat-icon svgIcon="clear"></mat-icon>
          </button>
        </mat-chip-row>
      }
      <input
        matInput
        placeholder="Model"
        #modelInput
        formControlName="modelSearch"
        [placeholder]="'Models'"
        [matAutocomplete]="auto"
        [matChipInputFor]="modelList"
      />
    </mat-chip-grid>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      @for (model of filteredModels | async; track model) {
        <mat-option [value]="model">
          {{ model.name }}
        </mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
  <button mat-mini-fab color="accent" (click)="search()">
    <mat-icon svgIcon="search"></mat-icon>
  </button>
</form>
