import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, HandleErrorsService } from 'app/app-services';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'request-reset-password',
  templateUrl: './request-reset-password.component.html',
  styleUrls: ['./request-reset-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestResetPasswordComponent {
  loading = new ReplaySubject<boolean>(1);

  constructor(
    private auth: AuthService,
    private snackBar: MatSnackBar,
    protected handleErrors: HandleErrorsService,
    protected translateService: TranslateService,
  ) {}

  requestPasswordReset(value: { email: string }) {
    this.loading.next(true);

    const role = 'USER';

    this.auth.requestPasswordReset({ ...value, role }).subscribe({
      next: () => {
        this.loading.next(false);
        this.translateService.get('send-reset-password-email').subscribe(message => {
          this.snackBar.open(message, 'OK', { duration: 7000 });
        });
      },
      error: err => {
        this.loading.next(false);
        this.translateService.get('email-not-found').subscribe(message => {
          this.snackBar.open(message, 'OK', { duration: 7000 });
        });
        this.handleErrors.handleErrors(err);
      },
    });
  }
}
