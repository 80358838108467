<h3>{{ 'reset-password-title' | translate }}</h3>
<form (ngSubmit)="resetPassword()" [formGroup]="resetPasswordForm">
  <mat-form-field>
    <mat-label translate="new-password" />
    <input matInput type="password" formControlName="newPassword" updateOnBlur />
    @if (resetPasswordForm.controls['newPassword'].hasError('required')) {
      <mat-error> {{ 'required' | translate }} </mat-error>
    }
  </mat-form-field>

  <mat-form-field>
    <mat-label translate="confirm-password" />
    <input matInput type="password" formControlName="confirmPassword" updateOnBlur />
    @if (resetPasswordForm.controls['confirmPassword'].hasError('required')) {
      <mat-error> {{ 'required' | translate }} </mat-error>
    }
  </mat-form-field>
  @if (!resetPasswordForm.controls['confirmPassword'].hasError('required') && resetPasswordForm.hasError('mismatch')) {
    <mat-error class="mismatch-error"> {{ 'mismatch' | translate }} </mat-error>
  }

  <button mat-raised-button color="accent" type="submit" [disabled]="resetPasswordForm.invalid || loading">
    @if (loading) {
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    } @else {
      {{ 'send' | translate }}
    }
  </button>
</form>
