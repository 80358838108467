import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'request-reset-password-form',
  templateUrl: './request-reset-password-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestResetPasswordFormComponent implements OnInit {
  @Input() loading: boolean;
  @Output() readonly requestPasswordReset = new EventEmitter();
  requestResetPasswordForm: FormGroup;
  disabled = false;

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.requestResetPasswordForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
    });
  }

  sendRequest() {
    this.requestPasswordReset.emit({
      email: this.requestResetPasswordForm.controls['email'].value,
    });
  }
}
