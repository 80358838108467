import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { fieldsMatch } from '../validators/fields-match.validator';

@Component({
  selector: 'reset-password-form',
  templateUrl: './reset-password-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordFormComponent implements OnInit {
  @Input() loading: boolean;
  @Output() readonly passwordReset = new EventEmitter();
  resetPasswordForm: FormGroup;

  ngOnInit() {
    this.resetPasswordForm = new FormGroup(
      {
        newPassword: new FormControl(null, Validators.required),
        confirmPassword: new FormControl(null, Validators.required),
      },
      fieldsMatch('newPassword', 'confirmPassword'),
    );
  }

  resetPassword() {
    this.passwordReset.emit(this.resetPasswordForm.controls['newPassword'].value);
  }
}
