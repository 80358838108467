import { Routes } from '@angular/router';
import { AntiGuard, IsLoggedGuard } from './guards';

import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { RequestResetPasswordComponent } from './request-reset-password/request-reset-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

export const appRoutes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [AntiGuard] },
  {
    path: '',
    component: DashboardComponent,
    canActivate: [IsLoggedGuard],
  },
  {
    path: 'request-password-reset',
    component: RequestResetPasswordComponent,
    canActivate: [AntiGuard],
  },
  {
    path: 'reset-password/:hash',
    component: ResetPasswordComponent,
    canActivate: [AntiGuard],
  },
];
