@if (currentPrintingMode === 'DAEMON') {
  <button mat-icon-button class="daemon-status-icon" [matTooltip]="isDaemonUp && currentPrinter ? currentPrinter : ('daemon-disconnected' | translate)">
    <mat-icon class="user-menu-icon" [svgIcon]="isDaemonUp && currentPrinter ? 'signal_wifi_statusbar_4' : 'signal_wifi_off'"></mat-icon>
  </button>
}
@if (currentPrintingMode === 'WEBUSB') {
  <button mat-icon-button class="daemon-status-icon" [matTooltip]="currentPrinter ? currentPrinter : ('printer-disconnected' | translate)">
    <mat-icon class="user-menu-icon" [svgIcon]="currentPrinter ? 'print' : 'print_disabled'"></mat-icon>
  </button>
}
<button mat-icon-button (click)="openProfileDialog()" [matTooltip]="'profile' | translate">
  <mat-icon svgIcon="settings"></mat-icon>
</button>

<button mat-icon-button (click)="navigate()" [matTooltip]="'tutoriel' | translate">
  <mat-icon svgIcon="help"></mat-icon>
</button>

@if (!loadingLogout) {
  <button mat-icon-button (click)="logout()" [matTooltip]="'logout' | translate">
    <mat-icon svgIcon="power_settings_new"></mat-icon>
  </button>
} @else {
  <button mat-icon-button>
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </button>
}
