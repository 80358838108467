{
  "name": "touch-plot",
  "version": "1.0.20",
  "license": "MIT",
  "author": {
    "email": "magic-cut@gmail.com",
    "name": "Magic cut",
    "url": "https://magic-cut.in/"
  },
  "description": "Magic cut Perfection at your fingertips",
  "scripts": {
    "ng": "ng",
    "start": "ng serve -o --port 4009",
    "build:icons": "node scripts/build-material-design-icons-sprite.js",
    "build:pwa": "npm run ng build -- --configuration pwa",
    "build:staging": "npm run build:icons && npm run ng build -- --configuration staging",
    "build:prod": "npm run build:icons && npm run ng build -- --configuration production",
    "ng:serve:pwa": "npm run ng build -- --configuration pwa && http-server -p 8083 -c-1 dist/",
    "test": "ng test",
    "lint": "ng lint",
    "lint-staged": "lint-staged",
    "format:check": "prettier --config .prettierrc --list-different \"src/{app,environments,assets}/**/*{.html,.ts,.js,.json,.css,.scss}\" --write",
    "prepare": "husky"
  },
  "lint-staged": {
    "**/*": "prettier --write --ignore-unknown",
    "**/*.ts": "eslint --max-warnings 0"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.11",
    "@angular/cdk": "^18.2.12",
    "@angular/common": "^18.2.11",
    "@angular/compiler": "^18.2.11",
    "@angular/core": "^18.2.11",
    "@angular/forms": "^18.2.11",
    "@angular/material": "^18.2.12",
    "@angular/platform-browser": "^18.2.11",
    "@angular/platform-browser-dynamic": "^18.2.11",
    "@angular/router": "^18.2.11",
    "@angular/service-worker": "^18.2.11",
    "@ngrx/effects": "^18.1.1",
    "@ngrx/entity": "^18.1.1",
    "@ngrx/eslint-plugin": "^18.1.1",
    "@ngrx/operators": "^18.1.1",
    "@ngrx/store": "^18.1.1",
    "@ngx-translate/core": "^16.0.3",
    "@ngx-translate/http-loader": "^16.0.0",
    "@swimlane/ngx-datatable": "^20.1.0",
    "@types/fabric": "^5.3.9",
    "@types/usb": "^2.0.5",
    "core-js": "^3.39.0",
    "fabric": "^6.4.3",
    "hammerjs": "^2.0.8",
    "lodash": "^4.17.21",
    "ngx-pipes": "^3.2.2",
    "nice-ticks": "^1.0.2",
    "rxjs": "^7.8.1",
    "tslib": "^2.8.0"
  },
  "devDependencies": {
    "@angular-builders/jest": "^18.0.0",
    "@angular-eslint/builder": "^17.2.1",
    "@angular-eslint/eslint-plugin": "^17.2.1",
    "@angular-eslint/eslint-plugin-template": "^17.2.1",
    "@angular-eslint/template-parser": "^17.2.1",
    "@angular/build": "^18.2.11",
    "@angular/cli": "^18.2.11",
    "@angular/compiler-cli": "^18.2.11",
    "@angular/language-service": "^18.2.11",
    "@commitlint/cli": "^19.5.0",
    "@commitlint/config-conventional": "^19.5.0",
    "@material-design-icons/svg": "^0.14.13",
    "@ngrx/schematics": "^18.1.1",
    "@ngrx/store-devtools": "^18.1.1",
    "@types/jest": "^29.5.14",
    "@types/lodash": "^4.17.13",
    "@types/node": "^22.9.0",
    "@typescript-eslint/eslint-plugin": "^6.7.2",
    "@typescript-eslint/parser": "^6.7.2",
    "country-state-city": "^3.2.1",
    "eslint": "^8.49.0",
    "eslint-config-prettier": "^9.0.0",
    "eslint-plugin-prettier": "^5.0.0",
    "husky": "^9.1.6",
    "jasmine-marbles": "^0.9.2",
    "jest": "^29.7.0",
    "jest-create-mock-instance": "^2.0.0",
    "jest-junit-reporter": "^1.1.0",
    "lint-staged": "^15.2.10",
    "native-ext-loader": "^2.3.0",
    "prettier": "^3.0.3",
    "rimraf": "^6.0.1",
    "ts-node": "^10.9.1",
    "typescript": "~5.5.4",
    "usb": "^2.14.0",
    "wait-on": "^8.0.1",
    "zone.js": "^0.14.3"
  }
}
