import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { Piece, PrinterType } from '../models/printable-data.model';
import { DaemonAddress, PrinterDetails, PrintingMode } from 'app/models/printing.model';
import { NotificationService } from 'app/app-services';
import { NotificationType } from 'app/models/notification-types.const';

@Component({
  selector: 'plotter-tab',
  templateUrl: './plotter-tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlotterTabComponent implements OnChanges, OnInit {
  @Input() filmWidth: number;
  @Input() loadedPieces: Piece[];
  @Input() plotterList: string[];
  @Input() printerList: PrinterDetails[];
  @Input() currentPlotter: string;
  @Input() currentPrinter: string;
  @Input() currentDaemon: string;
  @Input() currentPrintingMode: PrintingMode;
  @Input() isDaemonUp: boolean;
  @Output() readonly selectPlotter = new EventEmitter();
  @Output() readonly selectPrinter = new EventEmitter();
  @Output() readonly selectDaemonHost = new EventEmitter();
  @Output() readonly selectPrintingMode = new EventEmitter();
  @Output() readonly changeFilmWidh = new EventEmitter();
  @Output() readonly updatePrinterListEmitter = new EventEmitter();
  @Output() readonly testPrint = new EventEmitter<PrinterType>();
  @Output() readonly addPermission = new EventEmitter();

  plotterTabForm: FormGroup;
  printerType = PrinterType;
  daemonAddressList: DaemonAddress[] = [
    { name: 'Local', hostName: 'localhost', port: '3000' },
    { name: 'Magic-Cut', hostName: 'magic-cut.local', port: '80' },
  ];

  constructor(private readonly notificationService: NotificationService) {}
  printingsMode = Object.keys(PrintingMode).filter(key => isNaN(Number(key)));

  ngOnChanges(changes: SimpleChanges) {
    if (!this.plotterTabForm) {
      this.buildForm();
    }

    if (changes.currentPrinter) {
      this.plotterTabForm.get('printer').setValue(this.currentPrinter);
    }

    if (changes.filmWidth) {
      this.plotterTabForm.get('filmWidth').setValue(this.filmWidth);
    }

    if (changes.loadedPieces) {
      this.loadedPieces && this.loadedPieces.length ? this.plotterTabForm.get('filmWidth').disable() : this.plotterTabForm.get('filmWidth').enable();
    }
    if (changes.currentDaemon) {
      this.plotterTabForm.get('daemonHost').setValue(this.currentDaemon);
    }

    if (changes.currentPrintingMode) {
      this.plotterTabForm.get('printingMode').setValue(this.currentPrintingMode);
    }
  }

  private buildForm() {
    this.plotterTabForm = new FormGroup({
      plotter: new FormControl(this.currentPlotter),
      filmWidth: new FormControl(this.filmWidth, [Validators.min(0), Validators.max(600), Validators.required]),
      printer: new FormControl(this.currentPrinter),
      daemonHost: new FormControl(this.currentDaemon),
      printingMode: new FormControl(this.currentPrintingMode),
    });
  }

  ngOnInit() {
    ['plotter', 'filmWidth', 'printer', 'daemonHost', 'printingMode'].map(m => {
      this.plotterTabForm
        .get(m)
        .valueChanges.pipe(debounceTime(500), distinctUntilChanged())
        .subscribe(c => {
          const ctrl = this.plotterTabForm.controls['filmWidth'];
          switch (m) {
            case 'plotter':
              this.selectPlotter.emit(c);
              break;

            case 'filmWidth':
              this.checkValue(ctrl);
              this.changeFilmWidh.emit(ctrl.value);
              break;

            case 'daemonHost':
              this.selectDaemonHost.emit(c);
              break;

            case 'printingMode':
              this.selectPrintingMode.emit(c);
              break;

            default:
              break;
          }
        });
    });
  }

  checkValue(control: AbstractControl) {
    if (control.errors) {
      if (control.errors['min']) {
        control.setValue(control.errors['min'].min);
      } else if (control.errors['max']) {
        control.setValue(control.errors['max'].max);
      }
    }
  }

  updatePrinterList() {
    this.updatePrinterListEmitter.emit();
  }

  onTestPrint(type: PrinterType) {
    this.testPrint.emit(type);
  }

  addUSBPermission(event: Event) {
    this.addPermission.emit();
    event.stopPropagation();
  }

  onSelectPrinter(printerName: string) {
    const link = `<a href='https://zadig.akeo.ie/' target='_blank'>Zadig</a>`;
    const printer = this.printerList.find(printer => printer.name === printerName);

    if (!printer.claimed) {
      this.notificationService.notify(NotificationType.PRINTER_UNCLAIMED, 7000, link);
      this.plotterTabForm.get('printer').setValue(null);

      return;
    }
    this.selectPrinter.emit(printerName);
  }
}
