import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

import { NotificationType } from '../../app/models/notification-types.const';
import { DomSanitizer } from '@angular/platform-browser';
import { CustomSnackbarComponent } from 'app/custom-snackbar/custom-snackbar.component';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly translate: TranslateService,
    private readonly sanitizer: DomSanitizer,
  ) {}

  notify(notificationType: NotificationType, duration = 7000, params = '') {
    this.translate.get(notificationType, { params }).subscribe((translatedMessage: string) => {
      !this.isHtmlText(translatedMessage)
        ? this.snackBar.open(translatedMessage, 'ok', { duration })
        : this.snackBar.openFromComponent(CustomSnackbarComponent, {
            data: { message: this.sanitizer.bypassSecurityTrustHtml(translatedMessage) },
            duration,
          });
    });
  }

  isHtmlText(text: string): boolean {
    const htmlRegex = /<\/?[a-z][\s\S]*>/i;

    return htmlRegex.test(text);
  }
}
