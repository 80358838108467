<form [formGroup]="loggingFiltreForm" novalidate>
  <mat-form-field>
    <input matInput name="fDate" [max]="maxFirstDate" formControlName="startDate" [matDatepicker]="sDate" [placeholder]="'start-date' | translate" #firstDate />
    @if (loggingFiltreForm?.controls['startDate']?.hasError('date')) {
      <mat-error> date </mat-error>
    }
    <mat-datepicker-toggle matSuffix [for]="sDate"></mat-datepicker-toggle>
  </mat-form-field>
  <mat-datepicker #sDate></mat-datepicker>

  <mat-form-field>
    <input
      matInput
      name="lDate"
      [min]="minLastDate"
      [max]="maxLastDate"
      formControlName="endDate"
      [matDatepicker]="eDate"
      [placeholder]="'end-date' | translate"
      #lastDate
    />
    @if (loggingFiltreForm?.controls['endDate']?.hasError('date')) {
      <mat-error> date </mat-error>
    }
    <mat-datepicker-toggle matSuffix [for]="eDate"></mat-datepicker-toggle>
  </mat-form-field>
  <mat-datepicker #eDate></mat-datepicker>

  <button mat-mini-fab color="accent" (click)="search()">
    <mat-icon svgIcon="search"></mat-icon>
  </button>
</form>
