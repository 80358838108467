@if (currentForm) {
  <span>
    {{ filterForm.controls['pieceType'].value.type | translate }} {{ filterForm.controls['brand'].value.name }} /
    {{ filterForm.controls['serie'].value.name }} /
    {{ filterForm.controls['model'].value.name }}
  </span>
}
@if (!currentForm) {
  <form [formGroup]="filterForm" novalidate>
    <mat-form-field>
      <mat-label translate="type"></mat-label>
      <input type="text" matInput formControlName="pieceType" [matAutocomplete]="types" />
      <mat-autocomplete #types="matAutocomplete" (optionSelected)="selectType($event)">
        @for (type of pieceTypeListAutocomplete | async; track type) {
          <mat-option [value]="type"> {{ type | translate }} </mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field>
      <mat-label translate="brand"></mat-label>
      <input type="text" matInput formControlName="brand" [matAutocomplete]="brands" />
      <mat-autocomplete #brands="matAutocomplete" (optionSelected)="selectBrand($event)" [displayWith]="displayFn">
        @for (brand of brandListAutocomplete | async; track brand) {
          <mat-option [value]="brand"> {{ brand.name | slice: 0 : 20 }} {{ brand?.name.length >= 20 ? '...' : '' }} </mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field>
      <mat-label translate="serie"></mat-label>
      <input type="text" id="serie" matInput formControlName="serie" [matAutocomplete]="series" />
      <mat-autocomplete #series="matAutocomplete" (optionSelected)="selectSerie($event)" [displayWith]="displayFn">
        @for (serie of serieListAutocomplete | async; track serie) {
          <mat-option [value]="serie"> {{ serie.name | slice: 0 : 20 }} {{ serie.name.length >= 20 ? '...' : '' }} </mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>
    <div class="model-preview-btn">
      <mat-form-field>
        <mat-label translate="model"></mat-label>
        <input type="text" id="model" matInput formControlName="model" [matAutocomplete]="models" />
        <mat-autocomplete class="models-list" #models="matAutocomplete" (optionSelected)="selectModel($event)" [displayWith]="displayFn">
          @for (model of modelListAutocomplete | async; track model) {
            <mat-option [value]="model"> {{ model.name | slice: 0 : 20 }} {{ model.name.length >= 20 ? '...' : '' }} </mat-option>
          }
        </mat-autocomplete>
      </mat-form-field>
      <button [disabled]="disableBtn" (click)="onOpenModelPreviewDialog()" mat-icon-button matSuffix>
        <mat-icon [matTooltip]="'model-preview' | translate" svgIcon="info"></mat-icon>
      </button>
    </div>
  </form>
}
@if (!currentForm) {
  <div class="btns">
    <button
      color="accent"
      mat-raised-button
      (click)="submitFilterForm()"
      [disabled]="filterForm.controls['model'].disabled || filterForm.controls['model'].invalid"
    >
      {{ 'filter' | translate }}
    </button>
    <button mat-raised-button color="primary" (click)="openDialogAssistance()">{{ 'assistance' | translate }}</button>
  </div>
}
