<h3>{{ 'request-reset-password-title' | translate }}</h3>
<form (ngSubmit)="sendRequest()" [formGroup]="requestResetPasswordForm">
  <mat-form-field>
    <mat-label translate="email"></mat-label>
    <input matInput type="email" formControlName="email" updateOnBlur />
    @if (requestResetPasswordForm.controls['email'].hasError('required')) {
      <mat-error> {{ 'required' | translate }} </mat-error>
    }
    @if (requestResetPasswordForm.controls['email'].hasError('email') && !requestResetPasswordForm.controls['email'].hasError('required')) {
      <mat-error> {{ 'email-validation' | translate }} </mat-error>
    }
  </mat-form-field>
  <button mat-raised-button color="accent" type="submit" [disabled]="requestResetPasswordForm.invalid || loading">
    @if (loading) {
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    } @else {
      {{ 'send' | translate }}
    }
  </button>
</form>
